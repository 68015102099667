import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import {getUrlFromImage} from "../components/Gallery";

// eslint-disable-next-line
export const ZwembadenVijversPageTemplate = class extends React.Component {
  componentDidMount() {
    let root = document.documentElement;
    root.style.setProperty('--tp-navbar-color', '#fff');
  }
  render() {
    return (
      <div>
      <section className="header compact extra">
      <div className="header-image" style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url(${getUrlFromImage(this.props.titleImage)})`}}></div>
        <div className="container">
          <h1 className="title">ZWEMBADEN, ZWEMVIJVERS <br/> EN ECOZWEMBADEN</h1>
        </div>
      </section>
      <section className="content container mt-6 mb-6">
        <p>Zwemvijver/ zwembad, dit is de eye-catcher van uw tuin. Wilt u een zwembad, zwemvijver of een combinatie van de twee? <br/>Elk project kan volledig op maat en naar uw wens gemaakt worden. Wij houden rekening met uw voorkeur en budget.</p>
      </section>
      <section className="content container mt-6 mb-6"> 
        <div className="row mt-5">
          {
            this.props.fotos.map(foto => {
              return (
                <div className="col-md-4">
                  <div className="foto-project" style={{background: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)),url(${getUrlFromImage(foto.image)}) top center / cover no-repeat`}}>
                    <span className="foto-text">{foto.text}</span>
                  </div>
                </div>
              )
            })
          }
        </div>
      </section>
    </div>
    );
  }
}

ZwembadenVijversPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const ZwembadenVijversPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ZwembadenVijversPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        fotos={post.frontmatter.fotosZwembadenvijvers}
        titleImage={post.frontmatter.titleImage}
      />
    </Layout>
  );
};

ZwembadenVijversPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ZwembadenVijversPage;

export const zwembadenVijversPageQuery = graphql`
  query ZwembadenVijversPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        titleImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 60) {
              ...GatsbyImageSharpFluid
            }
            original {
              width
              height
            }
          }
        }
        fotosZwembadenvijvers {
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 60) {
                ...GatsbyImageSharpFluid
              }
              original {
                width
                height
              }
            }
          }
          text
        }
      }
    }
  }
`;
